import React, { useState } from "react"
import { InfoWindow } from "@react-google-maps/api"
import { RegionNetworksFieldsFragment } from "../../../../../../graphql-types"
import { useIsMobile, useMapInfoWindowStyle } from "../../../../hooks"
import PopIn from "../popin/popin"
import MarkersFiltered from "../markers-filtered/markers-filtered"

type RenderProps = {
  transportNetworks: RegionNetworksFieldsFragment[]
  urlIconMarkerClusterer: string
}

const MarkersGrouped: React.FC<RenderProps> = ({ transportNetworks, urlIconMarkerClusterer }) => {
  const isMobile = useIsMobile()
  const [selectedNetwork, setSelectedNetwork] = useState<RegionNetworksFieldsFragment>(null)
  const handleMarkerClick = (element: RegionNetworksFieldsFragment) => setSelectedNetwork(element)

  return (
    <>
      {selectedNetwork && Object.keys(selectedNetwork).length > 0 && (
        <>
          <style>{useMapInfoWindowStyle(isMobile)}</style>
          <InfoWindow
            onCloseClick={() => setSelectedNetwork(null)}
            options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
            position={{
              lat: selectedNetwork.networkLocation.latitude,
              lng: selectedNetwork.networkLocation.longitude,
            }}
          >
            <PopIn data={selectedNetwork} />
          </InfoWindow>
        </>
      )}
      <MarkersFiltered
        transportNetworks={transportNetworks}
        selectedNetwork={selectedNetwork}
        onClick={handleMarkerClick}
        urlIconMarkerClusterer={urlIconMarkerClusterer}
      />
    </>
  )
}

export default MarkersGrouped
