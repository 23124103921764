export const useMapInfoWindowStyle = isMobile => {
  return `
    .gm-style-iw-c {
      padding: 0 !important;
      max-height: none !important;
      max-width: ${isMobile ? "90vw" : "none"} !important;
    }
    
    .gm-style-iw-d::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }
    
    .gm-ui-hover-effect {
      width: 28px !important;
      height: 28px !important;
      top: 4px !important;
      right: 6px !important;
      opacity: 1 !important;
    }
    
    .gm-ui-hover-effect > span {
      width: 28px !important;
      height: 28px !important;
      margin: 0 !important;
      background-color: #fff;
    }
    
    [dir="rtl"] {
      .gm-ui-hover-effect {
        left: 6px !important;
        right: initial !important;
      }
    }
  `
}
