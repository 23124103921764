import React from "react"
import { graphql } from "gatsby"
import { MapModuleFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./map-module.module.scss"
import { useSlugs } from "../../../context/slugs-context"
import FilterBar from "../../tools/filter-bar/filter-bar"
import Capsule from "../../tools/filter-bar/capsule/capsule"
import Carousel from "./carousel/carousel"

type RenderProps = {
  data: MapModuleFieldsFragment
}

const MapModule: React.FC<RenderProps> = ({ data }) => {
  const { mapPageSlug } = useSlugs()

  return (
    <div className={data.isBackgroundDisplayed ? styles.background : ""}>
      <div className={styles.header}>
        <h2 className={styles.title}>{data.title}</h2>
        <div className={styles.content}>
          {(data.filters || data.text) && (
            <div className={styles.filtersColumn}>
              {data.filters.length > 0 && <FilterBar capsules={[...data.filters.map(filter => ({ label: filter.label }))]} />}
              {data.text && <p className={styles.text}>{data.text}</p>}
            </div>
          )}
          {data.buttonLabel && (
            <a href={mapPageSlug}>
              <Capsule title={data.buttonLabel} isSelected={false} special={true} />
            </a>
          )}
        </div>
      </div>
      <div className={styles.imagesContainer}>
        <div className={styles.carouselContainer}>
          <Carousel links={data.carouselLinks} />
        </div>
        <div className={styles.mainImage}>
          <img src={data.image?.url} alt={data.image?.alt || ""} />
        </div>
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment MapModuleFields on DatoCmsHomePageMapModule {
    isEnabled
    title
    text
    image {
      alt
      url
    }
    buttonLabel
    isBackgroundDisplayed
    filters {
      label
    }
    carouselLinks {
      ...CarouselFields
    }
  }
`

export default MapModule
