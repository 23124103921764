import React from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, TypePageQuery } from "../../../../graphql-types"
import sanitizeHtml from "sanitize-html"
import * as config from "../../../../config"
import * as styles from "./type-page.module.scss"
import * as globalStyles from "../../../global.module.scss"
import { useFormatLocale, useIsMobile } from "../../hooks"
import Layout from "../../components/layout/layout"
import Breadcrumb from "../../components/breadcrumb/breadcrumb"
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich_dato_cms_content"
import MetaData from "../../components/tools/meta-data/meta-data"
import InternalMesh from "../../components/internal-mesh/internal-mesh"
import Banner from "../../components/banner/banner"
import SEO from "../../components/seo/seo"
import { HrefLangLinks } from "../../components/hrefLangLinks/hrefLangLinks"

type RenderProps = {
  data: TypePageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
    allSlugLocales: { locale: string; value: string }[]
    currentSlug: string
    headerMenuLinksSlugs: { page: { slug: string } }[]
  }
}

const TypePage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const isMobile = useIsMobile()
  const page = data.datoCmsPage
  const sanitizedChapo = sanitizeHtml(page.chapo)

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute="/"
      pageTemplate="type"
      allSlugLocales={pageContext.allSlugLocales}
    >
      <Banner title={page.title} image={page.image} />

      <section className={`${globalStyles.typePageContainer} ${styles.metaData}`}>
        {!isMobile && (
          <Breadcrumb
            title={page.title}
            homeLabel={data.datoCmsGlobalConfiguration.homePageTitle}
            middlePages={page.middlePages}
          />
        )}

        <MetaData
          socialNetworks={data.datoCmsGlobalConfiguration.v3SocialNetworks}
          shareLabel={data.datoCmsGlobalConfiguration.v3ShareLabel}
          mailSubject={`${page.title} | ${config.site_name}`}
          publicationDate={page.v3PublicationDate}
        />
        {sanitizedChapo && <div dangerouslySetInnerHTML={{ __html: sanitizedChapo }} className={styles.chapo} />}
      </section>

      <RichDatoCmsContent data={page.richContent} socialNetworks={data.datoCmsGlobalConfiguration.v3SocialNetworks} />

      {page.internalMesh && (
        <div className={styles.internalMeshWrapper}>
          <InternalMesh data={page.internalMesh} />
        </div>
      )}
    </Layout>
  )
}

export const Head: React.FC<RenderProps> = ({ data, pageContext }) => (
  <>
    <SEO
      title={data.datoCmsPage.titleTag}
      description={data.datoCmsPage.metaDescription}
      noindex={data.datoCmsPage.noindex}
      locale={useFormatLocale(pageContext.locale)}
    />
    {typeof window !== "undefined" && (
      <>
        <link
          rel="canonical"
          href={data.datoCmsPage.isCanonical ? data.datoCmsPage.urlCanonical : window.location.href}
        />
        <HrefLangLinks
          defaultDomain={window.location.origin}
          defaultLocale={pageContext.defaultLocale}
          slugs={[pageContext.allSlugLocales]}
        />
      </>
    )}
  </>
)

export const pageQuery = graphql`
  query TypePage($locale: String!, $id: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
      v3ShareLabel
      v3SocialNetworks {
        ...V3SocialNetworkFields
      }
    }
    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsPage(locale: $locale, id: { eq: $id }) {
      ...V3TypePageFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }
  }
`

export const fragment = graphql`
  fragment V3TypePageFields on DatoCmsPage {
    id
    titleTag
    metaDescription
    noindex
    isCanonical
    urlCanonical
    title
    subtitle
    colorTitle {
      hex
    }
    colorSubtitle {
      hex
    }
    slug
    image {
      alt
      format
      title
      url
    }
    chapo
    v3PublicationDate
    publicationEndDate
    middlePages {
      ...InternalExternalLinkBlockFields
    }
    richContent {
      ...RichContentFields
    }
    ...ReadMorePageFields
    ...UsefulInfoPageFields
    internalMesh {
      ...InternalMeshFields
    }
  }
`

export default TypePage
