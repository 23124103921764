import React from "react"
import { Marker, MarkerClusterer } from "@react-google-maps/api"
import { ClustererOptions, ClusterIconStyle } from "@react-google-maps/marker-clusterer"
import { RegionNetworksFieldsFragment } from "../../../../../../graphql-types"
import SelectedMarker from "../../../../../../static/images/selected-marker.png"
import UnselectedMarker from "../../../../../../static/images/marker.png"

type RenderProps = {
  transportNetworks: RegionNetworksFieldsFragment[]
  selectedNetwork: RegionNetworksFieldsFragment
  onClick: (element: RegionNetworksFieldsFragment) => void
  urlIconMarkerClusterer: string
}

const MarkersFiltered: React.FC<RenderProps> = ({
  transportNetworks,
  selectedNetwork,
  onClick,
  urlIconMarkerClusterer,
}) => {
  const isSelectedButton = element => {
    return (
      selectedNetwork &&
      Object.keys(selectedNetwork).length !== 0 &&
      element?.networkName?.toString().localeCompare(selectedNetwork?.networkName?.toString()) == 0
    )
  }
  const options: ClustererOptions = {
    imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  }
  const clusterStyle: ClusterIconStyle = {
    url: urlIconMarkerClusterer,
    height: 68,
    width: 68,
    fontFamily: "Raleway Regular, sans-serif",
    fontStyle: "normal",
    textColor: "white",
    textSize: 18,
  }

  return (
    <MarkerClusterer
      options={options}
      styles={[
        {
          ...clusterStyle,
          height: 52,
          width: 52,
          textSize: 16,
        },
        clusterStyle,
        clusterStyle,
        clusterStyle,
        clusterStyle,
      ]}
    >
      {clusterer =>
        (transportNetworks as any)?.map((element: RegionNetworksFieldsFragment, indexElement) => (
          <Marker
            title={element.networkName}
            position={{
              lat: element.networkLocation.latitude,
              lng: element.networkLocation.longitude,
            }}
            icon={{
              url: isSelectedButton(element) ? SelectedMarker : UnselectedMarker,
              scaledSize: {
                width: isSelectedButton(element) ? 57 : 16.5,
                height: isSelectedButton(element) ? 57 : 22,
                equals: null,
              },
            }}
            onClick={() => onClick(element)}
            clusterer={clusterer}
            key={indexElement}
          />
        ))
      }
    </MarkerClusterer>
  )
}

export default MarkersFiltered
