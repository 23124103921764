import React, { useState } from "react"
import { graphql } from "gatsby"
import { V3HomePageRecruitmentFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./recruitment.module.scss"
import sanitizeHtml from "sanitize-html"
import { useSlugs } from "../../../context/slugs-context"
import Capsule from "../../tools/filter-bar/capsule/capsule"
import Carousel from "./carousel/carousel"

type RenderProps = {
  data: V3HomePageRecruitmentFieldsFragment
}

const Recruitment: React.FC<RenderProps> = ({ data }) => {
  const { getInternalExternalLink } = useSlugs()
  const sanitizedText = sanitizeHtml(data.text)
  const [currentLink, setCurrentLink] = useState(0)
  const article = data.recruitment[currentLink]

  const handleScroll = (scrollDirection: number) => {
    if (scrollDirection === 1 && currentLink < data.recruitment.length - 1) {
      setCurrentLink(currentLink + 1)
    } else if (scrollDirection === -1 && currentLink > 0) {
      setCurrentLink(currentLink - 1)
    }
  }

  return (
    <div className={data.isBackgroundDisplayed ? styles.background : ""}>
      <div className={styles.mainInformations}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{data.title}</h2>
        </div>
        <div className={styles.rightContainer}>
          <p className={styles.text} dangerouslySetInnerHTML={{ __html: sanitizedText }} />
          <Capsule
            className={styles.capsule}
            title={data.buttonLabel}
            isSelected={false}
            special={true}
            link={
              data.redirectLink
                ? {
                    url: data.redirectLink && getInternalExternalLink(data.redirectLink),
                    isInternal: data.redirectLink.isInternalPage,
                  }
                : null
            }
          />
        </div>
      </div>
      {data.recruitment.length > 0 && (
        <div className={styles.imagesContainer}>
          <div className={styles.carouselContainer}>
            <Carousel article={article} arrows={data.recruitment.length > 1} onScroll={handleScroll} />
          </div>
          <div className={styles.mainImage}>
            {article.imageOrVideo
              ? article.urlVideo && (
                  <iframe
                    src={article.urlVideo}
                    title="Video player"
                    tabIndex={-1}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-presentation allow-modals"
                    loading="lazy"
                    allowFullScreen
                  />
                )
              : article.image && <img src={article.image.url} alt={article.image.alt || ""} />}
          </div>
        </div>
      )}
    </div>
  )
}

export const fragment = graphql`
  fragment V3HomePageRecruitmentFields on DatoCmsV3HomePageRecruitment {
    isEnabled
    title
    text
    buttonLabel
    redirectLink {
      ...InternalExternalLinkBlockFields
    }
    isBackgroundDisplayed
    recruitment {
      ...V3RecruitmentFields
    }
  }
`

export default Recruitment
