import React, { useState } from "react"
import { graphql } from "gatsby"
import { DatoCmsSlugsConfiguration, MapPageQuery } from "../../../../graphql-types"
import Layout from "../../components/layout/layout"
import * as styles from "./map.module.scss"
import { srOnly } from "../../../global.module.scss"
import { useSlugValue } from "../../hooks"
import Filters from "./filters/filters"
import GoogleMapWrapper from "./google-map-wrapper/google-map-wrapper"

type RenderProps = {
  data: MapPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    slugs: DatoCmsSlugsConfiguration
  }
}

export type TransportModes = {
  name: string
  isSelected: boolean
}

const Map: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const mapData = data.datoCmsHomePageNetwork
  const transportModes = data.allDatoCmsKeolisTransportMode.nodes
  const [transportModesSelected, setTransportModesSelected] = useState<TransportModes[]>(
    transportModes.map(mode => ({ name: mode.transportModeName, isSelected: true }))
  )

  const handleClick = (name: string) => {
    if (name === "") {
      setTransportModesSelected(transportModes.map(mode => ({ name: mode.transportModeName, isSelected: true })))
    } else {
      const allFiltersSelected = transportModesSelected.filter(item => item.isSelected === false).length === 0
      const updatedList = transportModesSelected.map(item => {
        if (item.name === name) {
          return { ...item, isSelected: allFiltersSelected ? true : !item.isSelected }
        } else if (allFiltersSelected) {
          return { ...item, isSelected: false }
        }
        return item
      })
      setTransportModesSelected(updatedList)
    }
  }

  const getTransportModesNames = (transportModes: TransportModes[]) =>
    transportModes.filter(item => item.isSelected === true).map(item => item.name)

  return (
    <Layout
      pageContext={pageContext}
      location={location}
      header={data.datoCmsV3Header}
      footer={data.datoCmsFooter}
      subsidiaryLogo={data.datoCmsGlobalConfiguration.subsidiaryLogo}
      contactButton={data.datoCmsV3ContactButton}
      currentRoute={useSlugValue(pageContext.slugs._allMapPageSlugLocales, pageContext.locale)}
      pageTemplate="map"
    >
      <div
        className={styles.networkContainer}
        style={{ "--block-color": mapData.blockColor.hex } as React.CSSProperties}
      >
        <h2 className={styles.title}>{mapData.title}</h2>
        <h2 className={srOnly}>{mapData.cartographyTitleAccessibilty}</h2>
        {mapData.isFilterEnabled && (
          <Filters
            allTransportModes={transportModes}
            transportModesSelected={transportModesSelected}
            labelSelectAll={mapData.labelSelectAll}
            onClick={handleClick}
          />
        )}
        <GoogleMapWrapper
          data={data.datoCmsHomePageNetwork}
          allTransportNetworks={data.allDatoCmsTransportNetwork.nodes}
          transportModesSelected={getTransportModesNames(transportModesSelected)}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MapPage($locale: String!) {
    datoCmsGlobalConfiguration(locale: $locale) {
      homePageTitle
      subsidiaryLogo {
        alt
        format
        originalId
        url
      }
    }

    datoCmsV3Header(locale: $locale) {
      ...V3HeaderFields
    }
    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }
    datoCmsV3ContactButton(locale: $locale) {
      ...V3ContactButtonFields
    }

    datoCmsHomePageNetwork(locale: $locale) {
      ...NetworkFields
    }
    allDatoCmsKeolisTransportMode(locale: $locale, sort: { position: ASC }) {
      nodes {
        ... on DatoCmsKeolisTransportMode {
          ...AllTransportModesFields
        }
      }
    }
    allDatoCmsTransportNetwork(locale: $locale) {
      nodes {
        ...RegionNetworksFields
      }
    }
  }
`

export default Map
