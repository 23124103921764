import React, { useRef } from "react"
import * as styles from "./thematic.module.scss"
import { graphql } from "gatsby"
import { ThematicFieldsFragment } from "../../../../../graphql-types"
import sanitizeHtml from "sanitize-html"
import { ReactSVG } from "react-svg"
import { useAssociatedColor } from "../../../hooks"
import Capsule from "../../tools/filter-bar/capsule/capsule"
import { useSlugs } from "../../../context/slugs-context"

type RenderProps = {
  data: ThematicFieldsFragment
}

const Thematic: React.FC<RenderProps> = ({ data }) => {
  const { getInternalExternalLink } = useSlugs()
  const cardsList = useRef(null)
  const sanitizedText = sanitizeHtml(data.introductionText)

  return (
    <div className={`${styles.thematicContainer} ${data.gradientBackground ? styles.background : ""}`}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.title}>{data.title}</h2>
        <p className={styles.textIntro} dangerouslySetInnerHTML={{ __html: sanitizedText }} />
        {data.redirectLink && (
          <a
            href={getInternalExternalLink(data.redirectLink)}
            target={data.redirectLink.isInternalPage ? "_self" : "_blank"}
          >
            <Capsule
              title={data.redirectLink.title || "Voir tout"}
              className={styles.button}
              isSelected={false}
              special={true}
            />
          </a>
        )}
      </div>
      <ul className={styles.cardsContainer} ref={cardsList}>
        {data.thematicCard.map((card, index) => {
          const sanitizedDescription = sanitizeHtml(data.thematicCard[index].description)
          return (
            <li
              key={index}
              className={styles.card}
              style={
                {
                  "--cardBgColor": card.cardBackgroundColor?.hex,
                  "--cardBgColorBis": useAssociatedColor(card.cardBackgroundColor?.hex),
                  "--cardBgColorHover": card.cardBackgroundColorHover?.hex,
                  "--cardBgColorBisHover": useAssociatedColor(card.cardBackgroundColorHover?.hex),
                  "--cardTextColor": card.cardTextColor?.hex,
                  "--cardTextColorHover": card.cardTextColorHover?.hex,
                } as React.CSSProperties
              }
            >
              <ReactSVG
                src={`/vectors/v3/mediaCards/card${(index % 4) + 1}.svg`}
                beforeInjection={svg => {
                  svg.setAttribute("preserveAspectRatio", "none")
                }}
              />
              <div
                className={styles.cardContent}
                style={{ justifyContent: sanitizedDescription.length === 0 ? "center" : "space-evenly" }}
              >
                <span className={styles.cardTitle}>{card.title}</span>
                <div className={styles.showOnHover}>
                  <p dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
                  {data.thematicCard[index].redirectLink && (
                    <a
                      href={getInternalExternalLink(data.thematicCard[index].redirectLink)}
                      target={data.thematicCard[index].redirectLink.isInternalPage ? "_self" : "_blank"}
                    >
                      <Capsule title={data.thematicCard[index].redirectLink.title} isSelected={false} special={false} className={styles.capsule} />
                    </a>
                  )}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Thematic

export const fragment = graphql`
  fragment ThematicFields on DatoCmsHomePageThematic {
    isEnabled
    title
    introductionText
    gradientBackground
    redirectLink {
      ...InternalExternalLinkBlockFields
    }
    thematicCard {
      ...ThematicCardFields
    }
  }
  fragment ThematicCardFields on DatoCmsThematicCard {
    title
    description
    cardBackgroundColor {
      hex
    }
    cardBackgroundColorHover {
      hex
    }
    cardTextColor {
      hex
    }
    cardTextColorHover {
      hex
    }
    redirectLink {
      ...InternalExternalLinkBlockFields
    }
  }
`
