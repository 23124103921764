import React, { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import { graphql } from "gatsby"
import { V3ContactButtonFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./button-sticky.module.scss"
import { useSlugs } from "../../../context/slugs-context"

type RenderProps = {
  data: V3ContactButtonFieldsFragment
}

const ButtonSticky: React.FC<RenderProps> = ({ data }) => {
  const { getInternalExternalLink } = useSlugs()
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    const handleKeyDown = (e) => {}

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <a
      href={getInternalExternalLink(data.redirectLink)}
      target={data.redirectLink.isInternalPage ? "_self" : "_blank"}
      className={isOpened ? styles.opened : styles.closed}
      style={{ "--button-color": data.buttonColor.hex, "--label-color": data.labelColor.hex } as React.CSSProperties}
      onMouseEnter={() => setIsOpened(true)}
      onMouseLeave={() => setIsOpened(false)}
    >
      <span className={styles.title}>{data.redirectLink.title}</span>
      <ReactSVG src={data.icon.url} className={styles.icon} />
    </a>
  )
}

export const fragment = graphql`
  fragment V3ContactButtonFields on DatoCmsV3ContactButton {
    isEnabled
    redirectLink {
      ...InternalExternalLinkBlockFields
    }
    buttonColor {
      hex
    }
    labelColor {
      hex
    }
    icon {
      alt
      format
      url
    }
  }
`

export default ButtonSticky
