import React from "react"
import { graphql } from "gatsby"
import { SimpleTextFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./simple-text.module.scss"
import sanitizeHtml from "sanitize-html"

type RenderProps = {
  data: SimpleTextFieldsFragment
  className?: string
}

const SimpleText: React.FC<RenderProps> = ({ data, className }) => {
  const downgradeHeadings = (content: string) => {
    /* Downgrade headings to avoid having more than one h1
       Can't restrict it directly in datoCMS when using TinyMCE */
    const result = content
      .replace(/<h5([^>]*)>/g, "<h6$1>")
      .replace(/<\/h5>/g, "</h6>")
      .replace(/<h4([^>]*)>/g, "<h5$1>")
      .replace(/<\/h4>/g, "</h5>")
      .replace(/<h3([^>]*)>/g, "<h4$1>")
      .replace(/<\/h3>/g, "</h4>")
      .replace(/<h2([^>]*)>/g, "<h3$1>")
      .replace(/<\/h2>/g, "</h3>")
      .replace(/<h1([^>]*)>/g, "<h2$1>")
      .replace(/<\/h1>/g, "</h2>")
    return result
  }
  const sanitizeHtmlWithImages = content =>
    sanitizeHtml(downgradeHeadings(content), {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]).concat(["p"]),
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        img: (sanitizeHtml.defaults.allowedAttributes.img || []).concat(["src", "alt", "width", "height", "title"]),
        p: (sanitizeHtml.defaults.allowedAttributes.p || []).concat(["style"]),
        table: (sanitizeHtml.defaults.allowedAttributes.table || []).concat(["style", "border"]),
        tbody: (sanitizeHtml.defaults.allowedAttributes.tbody || []).concat(["style", "width", "height"]),
        tr: (sanitizeHtml.defaults.allowedAttributes.tr || []).concat(["style", "width", "height"]),
        td: (sanitizeHtml.defaults.allowedAttributes.td || []).concat(["style", "width", "height"]),
        span: (sanitizeHtml.defaults.allowedAttributes.span || []).concat(["style", "width", "height"]),
        li: (sanitizeHtml.defaults.allowedAttributes.li || []).concat(["style"]),
        ul: (sanitizeHtml.defaults.allowedAttributes.ul || []).concat(["style"]),
        ol: (sanitizeHtml.defaults.allowedAttributes.ol || []).concat(["style"]),
        blockquote: (sanitizeHtml.defaults.allowedAttributes.blockquote || []).concat(["style"]),
        div: (sanitizeHtml.defaults.allowedAttributes.div || []).concat(["style"]),
        a: (sanitizeHtml.defaults.allowedAttributes.a || []).concat(["href"]),
      },
      allowedSchemesByTag: {
        ...sanitizeHtml.defaults.allowedSchemesByTag,
        a: ["http", "https", "javascript", "mailto"],
      },
      exclusiveFilter: frame =>
        frame.tag === "a" &&
        frame.attribs.href &&
        frame.attribs.href.startsWith("javascript") &&
        !frame.attribs.href.startsWith("javascript:Didomi"),
    })

  return (
    <p
      key={data.id}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtmlWithImages(data.simpleContent).replace(new RegExp("<p></p>", "g"), "<br>"),
      }}
      className={className ? `${className} ${styles.simpleText}` : styles.simpleText}
    />
  )
}

export const fragments = graphql`
  fragment SimpleTextFields on DatoCmsSimpleText {
    id
    simpleContent
  }
`

export default SimpleText
