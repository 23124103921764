import React from "react"
import { graphql } from "gatsby"
import {
  BiographicCardHorizontalFieldsFragment,
  BiographicCardVerticalFieldsFragment,
  ButtonFieldsFragment,
  IframeFieldsFragment,
  ImageFieldsFragment,
  KeyNumberColoredBackgroundFieldsFragment,
  KeyNumberColoredFieldsFragment,
  KeyNumberSimpleFieldsFragment,
  NetworkContactFieldsFragment,
  OutlinedTextBlockFieldsFragment,
  PartnersContentFieldsFragment,
  V3PresentationTypePageFieldsFragment,
  V3QuoteFieldsFragment,
  RelatedContentFieldsFragment,
  SimpleTextFieldsFragment,
  SliderFieldsFragment,
  StructuredTextFieldsFragment,
  TransportModeFieldsFragment,
  V3BlockToPdfFieldsFragment,
  V3DiaporamaFieldsFragment,
  V3ImageFieldsFragment,
  V3KeyFiguresBlockFieldsFragment,
  V3SocialNetworkFieldsFragment,
  V3StructuredTextFieldsFragment,
  V3VideoFieldsFragment,
  VideoFieldsFragment,
  V3TextCarouselFieldsFragment,
  V3BlockSameTopicFieldsFragment,
} from "../../../../graphql-types"
import * as styles from "./rich_dato_cms_content.module.scss"
import BiographicCardHorizontal from "./biographic-card/horizontal/biographic-card-horizontal"
import Button from "./button/button"
import HighLight from "../highlight/highlight"
import Image from "../image/image"
import IFrame from "./iframe/iframe"
import KeyNumbers from "./key-numbers/key-numbers"
import NetworkContact from "./network-contact/network-contact"
import OutlinedTextBlock from "./outlined-text-block/outlined-text-block"
import RelatedContent from "./related-content/related-content"
import SimpleText from "./simple-text/simple-text"
import Slider from "./slider/slider"
import StructuredTextContent from "./structured-text-content/structured-text-content"
import TransportMode from "./transport-mode/transport-mode"
import Video from "./video/video"
import Quote from "./quote/quote"
import Presentation from "./presentation/presentation"
import Partners from "./partners/partners"
import KeyFigures from "../key-figures/key-figures"
import V3StructuredTextContent from "./structured-text-content/v3-structured-text-content"
import V3Video from "./v3-video/v3-video"
import V3Image from "./v3-image/v3-image"
import V3Diaporama from "./v3-diaporama/v3-diaporama"
import V3BiographicCardVertical from "./biographic-card/vertical/v3-biographic-card-vertical"
import V3BlockToPdf from "./v3-block-to-pdf/v3-block-to-pdf"
import V3TextCarousel from "./v3-text-carousel/v3-text-carousel"

type RichDatoCmsContentUnion = { __typename?: string } & (
  | BiographicCardHorizontalFieldsFragment
  | BiographicCardVerticalFieldsFragment
  | ButtonFieldsFragment
  | IframeFieldsFragment
  | ImageFieldsFragment
  | KeyNumberColoredBackgroundFieldsFragment
  | KeyNumberColoredFieldsFragment
  | KeyNumberSimpleFieldsFragment
  | NetworkContactFieldsFragment
  | OutlinedTextBlockFieldsFragment
  | RelatedContentFieldsFragment
  | SimpleTextFieldsFragment
  | SliderFieldsFragment
  | StructuredTextFieldsFragment
  | TransportModeFieldsFragment
  | VideoFieldsFragment
  | V3PresentationTypePageFieldsFragment
  | PartnersContentFieldsFragment
  | V3QuoteFieldsFragment
  | V3KeyFiguresBlockFieldsFragment
  | V3StructuredTextFieldsFragment
  | V3DiaporamaFieldsFragment
  | V3ImageFieldsFragment
  | V3VideoFieldsFragment
  | V3TextCarouselFieldsFragment
  | V3BlockSameTopicFieldsFragment
)

type RenderProps = {
  data: RichDatoCmsContentUnion[]
  socialNetworks?: V3SocialNetworkFieldsFragment[]
}

const RichDatoCmsContent: React.FC<RenderProps> = ({ data, socialNetworks }) => {
  const fetchRichDatoCmsContent = (richContent: RichDatoCmsContentUnion, index: number) => {
    switch (richContent.__typename) {
      case "DatoCmsBiographicCardHorizontal":
        return <BiographicCardHorizontal data={richContent as BiographicCardHorizontalFieldsFragment} key={index} />
      case "DatoCmsBiographicCardVertical":
        return (
          <V3BiographicCardVertical
            data={richContent as BiographicCardVerticalFieldsFragment}
            socialNetworks={socialNetworks}
            key={index}
          />
        )
      case "DatoCmsButton":
        return <Button data={richContent as ButtonFieldsFragment} key={index} />
      case "DatoCmsIframe":
        return <IFrame data={richContent as IframeFieldsFragment} key={index} />
      case "DatoCmsImage":
        return <Image data={richContent as ImageFieldsFragment} key={index} />
      case "DatoCmsKeyNumberColoredBackground":
        return <KeyNumbers data={richContent as KeyNumberColoredBackgroundFieldsFragment} key={index} />
      case "DatoCmsKeyNumberColored":
        return <KeyNumbers data={richContent as KeyNumberColoredFieldsFragment} key={index} />
      case "DatoCmsKeyNumberSimple":
        return <KeyNumbers data={richContent as KeyNumberSimpleFieldsFragment} key={index} />
      case "DatoCmsContactNetwork":
        return <NetworkContact data={richContent as NetworkContactFieldsFragment} key={index} />
      case "DatoCmsOutlinedTextBlock":
        return <OutlinedTextBlock data={richContent as OutlinedTextBlockFieldsFragment} />
      case "DatoCmsRelatedContent":
        return <RelatedContent data={richContent as RelatedContentFieldsFragment} key={index} />
      case "DatoCmsSimpleText":
        return <SimpleText data={richContent as SimpleTextFieldsFragment} key={index} />
      case "DatoCmsSlider":
        return <Slider data={richContent as SliderFieldsFragment} key={index} />
      case "DatoCmsStructuredText":
        return <StructuredTextContent data={richContent as StructuredTextFieldsFragment} key={index} />
      case "DatoCmsTransportMethodContent":
        return <TransportMode data={richContent as TransportModeFieldsFragment} key={index} />
      case "DatoCmsVideo":
        return <Video data={richContent as VideoFieldsFragment} key={index} />
      case "DatoCmsQuote":
        return <Quote data={richContent as V3QuoteFieldsFragment} key={index} />
      case "DatoCmsPresentation":
        return <Presentation data={richContent as V3PresentationTypePageFieldsFragment} key={index} />
      case "DatoCmsPartnersContent":
        return <Partners data={richContent as PartnersContentFieldsFragment} key={index} />
      case "DatoCmsV3KeyFiguresBlock":
        return <KeyFigures data={richContent as V3KeyFiguresBlockFieldsFragment} key={index} />
      case "DatoCmsV3StructuredText":
        return <V3StructuredTextContent data={richContent as V3StructuredTextFieldsFragment} key={index} />
      case "DatoCmsV3Diaporama":
        return <V3Diaporama data={richContent as V3DiaporamaFieldsFragment} key={index} />
      case "DatoCmsV3Image":
        return <V3Image data={richContent as V3ImageFieldsFragment} key={index} />
      case "DatoCmsV3Video":
        return <V3Video data={richContent as V3VideoFieldsFragment} key={index} />
      case "DatoCmsV3BlockToPdf":
        return <V3BlockToPdf data={richContent as V3BlockToPdfFieldsFragment} key={index} />
      case "DatoCmsV3TextsCarousel":
        return <V3TextCarousel data={richContent as V3TextCarouselFieldsFragment} key={index} />
      case "DatoCmsV3BlockSameTopic":
        return <HighLight data={richContent as V3BlockSameTopicFieldsFragment} key={index} />
    }
  }

  return <section className={styles.richContent}>{data?.map(fetchRichDatoCmsContent)}</section>
}

export const fragment = graphql`
  fragment RichContentFields on DatoCmsUnionForDatoCmsPressReleaseRichContent {
    __typename
    ... on DatoCmsStructuredText {
      ...StructuredTextFields
    }
    ... on DatoCmsVideo {
      ...VideoFields
    }
    ... on DatoCmsTransportMethodContent {
      ...TransportModeFields
    }
    ... on DatoCmsButton {
      ...ButtonFields
    }
    ... on DatoCmsContactNetwork {
      ...NetworkContactFields
    }
    ... on DatoCmsRelatedContent {
      ...RelatedContentFields
    }
    ... on DatoCmsImage {
      ...ImageFields
    }
    ... on DatoCmsOutlinedTextBlock {
      ...OutlinedTextBlockFields
    }
    ... on DatoCmsSlider {
      ...SliderFields
    }
    ... on DatoCmsKeyNumberSimple {
      ...KeyNumberSimpleFields
    }
    ... on DatoCmsKeyNumberColored {
      ...KeyNumberColoredFields
    }
    ... on DatoCmsKeyNumberColoredBackground {
      ...KeyNumberColoredBackgroundFields
    }
    ... on DatoCmsIframe {
      ...IframeFields
    }
    ... on DatoCmsBiographicCardHorizontal {
      ...BiographicCardHorizontalFields
    }
    ... on DatoCmsBiographicCardVertical {
      ...BiographicCardVerticalFields
    }
    ... on DatoCmsSimpleText {
      ...SimpleTextFields
    }
    ... on DatoCmsPresentation {
      ...V3PresentationTypePageFields
    }
    ... on DatoCmsPartnersContent {
      ...PartnersContentFields
    }
    ... on DatoCmsQuote {
      ...V3QuoteFields
    }
    ... on DatoCmsV3KeyFiguresBlock {
      ...V3KeyFiguresBlockFields
    }
    ... on DatoCmsV3StructuredText {
      ...V3StructuredTextFields
    }
    ... on DatoCmsV3Diaporama {
      ...V3DiaporamaFields
    }
    ... on DatoCmsV3Image {
      ...V3ImageFields
    }
    ... on DatoCmsV3Video {
      ...V3VideoFields
    }
    ... on DatoCmsV3BlockToPdf {
      ...V3BlockToPdfFields
    }
    ... on DatoCmsV3TextsCarousel {
      ...V3TextCarouselFields
    }
    ... on DatoCmsV3BlockSameTopic {
      ...V3BlockSameTopicFields
    }
  }
`

export default RichDatoCmsContent
