import React, { Fragment, useEffect, useState } from "react"
import { AllTransportModesFieldsFragment } from "../../../../../graphql-types"
import * as styles from "./filters.module.scss"
import { useIsMobile } from "../../../../hooks"
import { TransportModes } from "../map"
import { DisplayImage } from "../../../../components/display-image/display-image"

type RenderProps = {
  allTransportModes: AllTransportModesFieldsFragment[]
  transportModesSelected: TransportModes[]
  labelSelectAll: string
  onClick: (value: string) => void
}

const Filters: React.FC<RenderProps> = ({ allTransportModes, transportModesSelected, labelSelectAll, onClick }) => {
  const isMobile = useIsMobile()

  const [isListExpanded, setIsListExpanded] = useState(false)
  const [allTransportModesHeight, setAllTransportModesHeight] = useState(0)

  const areAllTransportModeSelected =
    transportModesSelected.filter(transportMode => transportMode.isSelected === true).length ===
    allTransportModes.length

  const handleListExpanded = () => setIsListExpanded(!isListExpanded)

  useEffect(() => {
    const allTransportModesMobile = document.getElementsByClassName(styles.allTransportModesMobile)

    if (allTransportModesMobile.length > 0) {
      setAllTransportModesHeight(allTransportModesMobile[0].clientHeight)
    }
  }, [isMobile])

  return (
    <ul
      className={`${isMobile ? styles.allTransportModesMobile : styles.allTransportModes} ${
        isMobile && isListExpanded && styles.listExpanded
      }`}
      style={isMobile && allTransportModesHeight > 0 ? { height: allTransportModesHeight } : {}}
    >
      {allTransportModes.map((mode, index) => {
        let button = <></>

        if (isMobile) {
          if ((index === 5 && !isListExpanded) || (isListExpanded && index + 1 === allTransportModes.length)) {
            button = (
              <>
                <li
                  className={areAllTransportModeSelected ? styles.capsule : styles.capsuleNotSelected}
                  onClick={() => onClick("")}
                >
                  <span className={styles.transportName}>{labelSelectAll}</span>
                </li>
                <li
                  className={styles.expandButton}
                  style={isListExpanded ? { lineHeight: "22px" } : { lineHeight: "26px" }}
                  onClick={handleListExpanded}
                >
                  {isListExpanded ? "-" : "+"}
                </li>
              </>
            )
          } else if (index > 5 && !isListExpanded) return
        }

        return (
          <Fragment key={index}>
            <li
              className={transportModesSelected[index].isSelected ? styles.capsule : styles.capsuleNotSelected}
              onClick={() => onClick(mode.transportModeName)}
              tabIndex={0}
            >
              <DisplayImage image={mode.transportModeIcon} svgStyle={styles.pictogram} imgStyle={styles.pictogram} />
              <span className={styles.transportName}>{mode.transportModeName}</span>
            </li>
            {button}
          </Fragment>
        )
      })}
      {!isMobile && (
        <li
          className={areAllTransportModeSelected ? styles.capsule : styles.capsuleNotSelected}
          onClick={() => onClick("")}
        >
          <span className={styles.transportName}>{labelSelectAll}</span>
        </li>
      )}
    </ul>
  )
}

export default Filters
