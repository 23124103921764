// extracted by mini-css-extract-plugin
export var image = "popin-module--image--c380d";
export var img = "popin-module--img--8ac21";
export var keyFigureDescription = "popin-module--keyFigureDescription--887c3";
export var keyFigureWrapper = "popin-module--keyFigureWrapper--4343d";
export var keyNumber = "popin-module--keyNumber--705c0";
export var link = "popin-module--link--0264b";
export var networkName = "popin-module--networkName--f586e";
export var pictogram = "popin-module--pictogram--8cf81";
export var popin = "popin-module--popin--77b06";
export var showMore = "popin-module--showMore--a0005";
export var transportMode = "popin-module--transportMode--908bb";
export var transportModeElement = "popin-module--transportModeElement--27229";
export var transportModeList = "popin-module--transportModeList--75bcd";