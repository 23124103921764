import React from "react"
import { RegionNetworksFieldsFragment } from "../../../../../../graphql-types"
import * as styles from "./popin.module.scss"
import { useLocale } from "../../../../context/locale-context"
import { DisplayImage } from "../../../../../components/display-image/display-image"

type RenderProps = {
  data: RegionNetworksFieldsFragment
}

const PopIn: React.FC<RenderProps> = ({ data }) => {
  const { locale, defaultLocale } = useLocale()

  const getNetworkLink = () => {
    if (!data.isInternalPage) {
      return data.url
    } else if (data.isContentPageSelected) {
      return locale === defaultLocale ? `/${data.contentPage.slug}` : `/${locale}/${data.contentPage.slug}`
    } else {
      return locale === defaultLocale ? `/network/${data.slug}` : `/${locale}/network/${data.slug}`
    }
  }

  return (
    <div className={styles.popin}>
      <div className={styles.networkName}>
        <span>{data.networkName}</span>
      </div>
      <div className={styles.transportMode}>
        <div className={styles.transportModeList}>
          {data.networkTransportModes.map((transportMode, index) => (
            <div className={styles.transportModeElement} key={index}>
              <div className={styles.image}>
                <DisplayImage
                  image={transportMode.transportModeIcon}
                  svgStyle={styles.pictogram}
                  imgStyle={styles.img}
                  pictogramColor="#000"
                />
              </div>
              <span>{transportMode.transportModeName}</span>
            </div>
          ))}
        </div>
        {data.enableDiscoverButton && (
          <>
            {data.networkKeyFigures.map((keyNumberNetwork, index) => (
              <div key={index} className={styles.keyFigureWrapper}>
                <div className={styles.keyNumber}>{keyNumberNetwork.keyFigure}</div>
                <div className={styles.keyFigureDescription}>{keyNumberNetwork.keyFigureDescription}</div>
              </div>
            ))}
            <a className={styles.link} href={getNetworkLink()} target={data.isInternalPage ? "_self" : "_blank"}>
              <div className={styles.showMore}>Découvrir</div>
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default PopIn
